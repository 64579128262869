import instance from "./axios";

export const getIssuers = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId,
  relationId,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}`;

  const data = await instance.get(
    `/client_issuer/all_client_issuer_paginate/${queryStringParameters}`,
  );
  // const data = await instance.get(`/policies/versions/654654654`);

  return data;
};

export const updateClientIssuer = async (body) => {
  const data = await instance.put(
    `/client_issuer/${body.id_pessoa_emitente}`,
    body,
  );

  return data;
};

export const postClientIssuer = async (body) => {
  const data = await instance.post("/client_issuer", body);

  return data;
};

export const updateStatusIssuer = async (idPessoaEmitente, statusCode) => {
  const data = await instance.put(`/client_issuer/status/${idPessoaEmitente}`, {
    tip_status: statusCode,
    cod_usuario: "teste",
  });

  return data;
};

export const deleteClientIssuer = async (idPessoaEmitente) => {
  const data = await instance.delete(`/client_issuer/${idPessoaEmitente}`, {
    data: {
      cod_usuario: "teste",
    },
  });

  return data;
};

export const getClientIssuerByIdPessoaEmitente = async (idPessoaEmitente) => {
  const data = await instance.get(`/client_issuer/get_id/${idPessoaEmitente}`);

  return data;
};

export const clientsByType = async (segurado, currentPage = 1, limit) => {
  const all = segurado.todos ? "todos" : "";
  const formated = JSON.stringify(segurado.data);
  const { data } = await instance.get(
    `/clients_by_type/emitente?page_number=${currentPage}&limit=${limit}&todos=${all}&ids=${formated}`,
  );

  return data;
};

export const getIssuerOptions = async (
  docIssuer,
  customerId,
  partnerId,
  relationId,
) => {
  const { data } = await instance.get(
    `/client_issuer/authorization/emitente?search_data=${docIssuer}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}`,
  );

  return data;
};

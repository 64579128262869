import instance from "./axios";

export const createKey = async (id, form) => {
  const data = await instance.post(`/acess_key/${id}`, form);

  return data;
};

export const getKeys = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  filterSystem,
  customerId,
  partnerId,
  relationId,
  issuerId,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_api=${filterSystem}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}&id_pessoa_emitente=${issuerId}`;
  const data = await instance.get(
    `/acess_key/all_acess_key_paginate/${queryStringParameters}`,
  );

  return data;
};

export const getSistems = async () => {
  const data = await instance.get("/get_types/api");

  return data;
};

export const getKeyById = async (id) => {
  const data = await instance.get(`/acess_key/get_id/${id}`);

  return data;
};

export const getIssuerByRelation = async (id) => {
  const data = await instance.get(
    `/client_issuer/all_client_issuer_by_relation_id/${id}`,
  );

  return data;
};

export const updateKeys = async (id, newStatus) => {
  const data = await instance.put(`/acess_key/edit/${id}`, {
    id_motivo_bloqueio: 1,
    tip_status: newStatus,
    cod_usuario: "inserção da chave",
  });

  return data;
};

export const deleteKey = async (id) => {
  const data = await instance.delete(`/acess_key/delete/${id}`, {
    data: { cod_usuario: "DELETE CHAVE" },
  });

  return data;
};

import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";

import Loading from "../components/Loading";

import ProtectedRoute from "./protected.route";

const Login = lazy(() => import("../pages/Login"));
//ROTA LOGIN

const Routes = () => (
  <Router>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/login/:id" children={<Login />} />
        <Route
          exact
          path="/"
          children={
            <Redirect
              to={{
                pathname: "/login/signin",
              }}
            />
          }
        />
        <ProtectedRoute />
        <Route
          path="*"
          children={
            <Redirect
              to={{
                pathname: "/login/signin",
              }}
            />
          }
        />
      </Switch>
    </Suspense>
  </Router>
);

export default withRouter(Routes);

import { Route, Redirect } from "react-router-dom";
import useAuthStore from "../../context/Auth/useAuthStore";

export default function PrivateRoute({ children, feature, ...rest }) {
  const { isLogged } = useAuthStore();

  // const renderChildren = (location) => {
  //   if (user.acceptedTerms) {
  //     return children;
  //   }

  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/main/termos",
  //         state: { from: location },
  //       }}
  //     />
  //   );
  // };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged ? (
          children
        ) : (
          // renderChildren(location)
          <Redirect
            to={{
              pathname: "/login/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

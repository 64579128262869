import instance from "./axios";

export const getRelation = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}`;
  const data = await instance.get(
    `/client_relation/all_client_relation_paginate/${queryStringParameters}`
  );
  return data;
};

export const updateStatusRelation = async (id, tip_status) => {
  const params = { tip_status: tip_status, cod_usuario: "Usuario Teste" };
  const data = await instance.put(`/client_relation/status/${id}`, params);

  return data;
};

export const updateClientRelation = async (id_pessoa_relacionamento, form) => {
  const data = await instance.put(
    `/client_relation/${id_pessoa_relacionamento}`,
    form
  );

  return data;
};

export const deleteRelation = async (id) => {
  const { data } = await instance.delete(`/client_relation/${id}`, {
    data: {
      cod_usuario: "Usuario Teste",
    },
  });

  return data;
};

export const createRelation = async (form) => {
  const { data } = await instance.post("/client_relation", form);

  return data;
};

export const getModalidade = async () => {
  const { data } = await instance.get("/get_types/modalidade_averbacao");

  return data;
};

export const getMeioAverbacao = async () => {
  const { data } = await instance.get("get_types/meio_averbacao");

  return data;
};

export const getClientRelationById = async (idPessoaRelacionamento) => {
  const data = await instance.get(
    `/client_relation/get_id/${idPessoaRelacionamento}`
  );

  return data;
};

export const clientsByType = async (
  parceiro,
  currentPage = 1,
  searchData,
  limit
) => {
  const all = parceiro.todos ? "todos" : "";
  const formated = JSON.stringify(parceiro.data);
  const { data } = await instance.get(
    `/clients_by_type/relacionamento?page_number=${currentPage}&limit=${limit}&search_data=${searchData}&todos=${all}&ids=${formated}`
  );
  return data;
};

export const getRelationOptions = async (
  docRelation,
  customerId,
  partnerId
) => {
  const { data } = await instance.get(
    `/client_issuer/authorization/relacionamento?search_data=${docRelation}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}`
  );

  return data;
};

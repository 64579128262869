import instance from "./axios";
import {} from "../";
import { maskOnlyNumbersToSubimit } from "../utils";

async function getIdClient(value, setState, setError) {
  if (!value) return;
  try {
    const response = await instance.get(
      `/client/client_by_code/${value.replace(/\D/g, "")}`,
    );
    const mainClientdPerson = response.data.shift();
    setState(mainClientdPerson.id_pessoa_cliente);
  } catch (error) {
    console.error({ error });
    setError({ error, client: error.response.data.error });
    return { error, client: error.response.data.error };
  }
}

async function getPartnerByClientId(value) {
  try {
    const response = await instance.get(
      `/client_partner/all_client_partner_by_client_id/${value}`,
    );
    return response.data.map((el) => ({
      label: el.nom_fantasia_parceiro,
      value: el.id_pessoa_parceiro,
    }));
  } catch (error) {
    console.error({ error });
    if (error.response.status === 404)
      return { error, partner: error.response.data.error };
  }
}

async function getRelationByPartnerId(value) {
  try {
    const response = await instance.get(
      `/client_relation/all_client_relation_by_partner_id/${value}`,
    );

    return response.data.map((el) => ({
      label: el.nom_fantasia_relacionamento,
      value: el.cod_documento_pessoa,
      tip_pessoa: el.tip_pessoa,
    }));
  } catch (error) {
    if (error.response.status === 404)
      return { error, relation: error.response.data.error };
    console.error({ error });
  }
}

async function getRamo() {
  try {
    const response = await instance.get(`/get_types/ramo`);
    const formatedResponse = response.data.map((el) => ({
      label: `${el.cod_sigla_ramo} - ${el.des_ramo}`,
      value: el.id_ramo,
    }));

    return formatedResponse;
  } catch (error) {
    console.error({ error });
  }
}

export async function getRamos() {
  try {
    const response = await instance.get(`/get_types/ramo`);
    const formatedResponse = response.data.map((el) => ({
      label: `${el.cod_sigla_ramo} - ${el.des_ramo}`,
      value: el.id_ramo,
    }));

    return formatedResponse;
  } catch (error) {
    console.error({ error });
  }
}

async function getPoliciesPaginate(
  pageNumber = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId,
  relationId,
) {
  try {
    const queryStringParameters = `${pageNumber}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}`;
    const response = await instance.get(
      `/policies/all_policies_paginate/${queryStringParameters}`,
    );

    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

async function getPerson(cod_documento_pessoa) {
  try {
    const response = await instance.get(
      `/person/check-cnpj-exists/${cod_documento_pessoa.replace(/\D/g, "")}`,
    );
    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

async function getPolicyStipuled(cod_documento_pessoa) {
  try {
    const response = await instance.get(
      `/policies-stipuled/${cod_documento_pessoa}`,
    );

    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

async function getPolicyById(id_apolice) {
  try {
    const response = await instance.get(`/policies/get_id/${id_apolice}`);

    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

export async function updatePolicyById(form) {
  try {
    const response = await instance.put(`/policies/${form.id_apolice}`, form);

    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

export const policiesUpdateUniqueByidApolice = async (form) => {
  const data = instance.put(`/policies/update_unique/${form.id_apolice}`, form);

  return data;
};

export async function updateImmediatePolicyById(form) {
  const { data } = await instance.put(`/policies/${form.id_apolice}`, form);

  return data;
}

export async function getPolicyNewValidates(
  id_apolice,
  pageNumber = 1,
  filterLimit = 10,
  filterSearch,
) {
  const path = `/policies/all_endosso_paginate/${id_apolice}?page_number=${pageNumber}&limit=${filterLimit}`;
  try {
    const response = await instance.get(path);
    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

export const getPolicyCoverageValidate = async (
  id_apolice_cobertura_adicional,
) => {
  try {
    const response = await instance.get(
      `policies/policies_coverage_validate/get_id/${id_apolice_cobertura_adicional}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createAddictionalValidateCoverage = async (formData) => {
  const params = {
    cod_usuario: "INSERT EXTRA COVERAGE",
    tip_usuario: formData.tip_usuario,
    id_extrapolacao: formData.id_extrapolacao,
    dhr_inicio_vigencia: formData.dhr_inicio_vigencia,
    dhr_fim_vigencia: formData.dhr_fim_vigencia,
    adicionais: [
      {
        cobertura_adicional: {
          id_tipo_cobertura_adicional: formData.id_tipo_cobertura_adicional,
        },
        cobertura_adicional_validade: {
          dta_inicio_validade: formData.dhr_inicio_vigencia,
          tip_valor: formData.tip_valor,
          vlr_cobertura: maskOnlyNumbersToSubimit(formData.vlr_cobertura),
          sta_agrega_lmg: formData.sta_agrega_lmg,
        },
      },
    ],
  };

  try {
    const data = await instance.put(
      `policies/addictional_coverage/${formData.id_apolice}`,
      params,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateAddictionalValidateCoverage = async (formData) => {
  const params = {
    cod_usuario: "UPDATE EXTRA COVERAGE",
    adicionais: [
      {
        cobertura_adicional: {
          id_tipo_cobertura_adicional: formData.id_tipo_cobertura_adicional,
        },
        cobertura_adicional_validade: {
          dta_inicio_validade: formData.dta_inicio_validade,
          id_apolice_cobertura_adicional_validade:
            formData.id_apolice_cobertura_adicional_validade,
          tip_valor: formData.tip_valor,
          vlr_cobertura: maskOnlyNumbersToSubimit(formData.vlr_cobertura),
          sta_agrega_lmg: formData.sta_agrega_lmg,
        },
      },
    ],
  };
  try {
    const data = await instance.put(
      `policies/addictional_coverage/${formData.id_apolice}`,
      params,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const deletePolicy = async (id_apolice) => {
  try {
    const response = await instance.delete(`/policies/${id_apolice}`, {
      data: {
        cod_usuario: "teste",
      },
    });

    return response;
  } catch (error) {
    console.error({ error });
  }
};

export const updatePolicyStatus = async (id_apolice, newStatusType) => {
  try {
    const response = await instance.put(`/policies/status/${id_apolice}`, {
      cod_usuario: "teste",
      tip_status: newStatusType,
    });
    return response;
  } catch (error) {
    console.error({ error });
  }
};

export const createPolicy = async (form) => {
  const response = await instance.post("/policies", form);

  return response;
};

export const getTypesExtrapolacao = async () => {
  const data = await instance.get("/get_types/extrapolacao");

  return data;
};

export const getTypesPrazoAverbacao = async () => {
  const data = await instance.get("/get_types/prazo_averbacao");

  return data;
};

export const getPoliciesByIssuer = async (issuerId, idApolice) => {
  const data = await instance.get(
    `/policies/get_policies_by_issuer/${issuerId}?id_apolice=${idApolice}`,
  );

  return data;
};

export const policiesAllEndossoAdditionalCoverage = async (policeId) => {
  const data = await instance.get(
    `/policies/all_endosso_additional_coverage/${policeId}`,
  );

  return data;
};

export const updatePolicyCoverageValidate = async (form) => {
  const data = await instance.put(
    `policies/update-policy-coverage-validate/${form.id_apolice_cobertura_adicional_validade}`,
    form,
  );

  return data;
};

export const getPolicyBaseByIdRelaction = async (
  idPessoaRelacionamento,
  estipulado,
) => {
  const data = await instance.get(
    `/policies/get_policie_base/${idPessoaRelacionamento}?estipulado=${estipulado}`,
  );

  return data;
};

export const policiesStipuledPolicie = async (form) => {
  const data = await instance.post(`/policies/stipuled_policie`, form);

  return data;
};

export const insertEndorsement = async (id_endorsement, form) => {
  const data = await instance.post(
    `/policies/insert_endosso/${id_endorsement}`,
    form,
  );

  return data;
};

export const getEndormentById = async (id_endorsement) => {
  const data = await instance.get(
    `/policies/get_endosso_by_id/${id_endorsement}`,
  );

  return data;
};

export const updateEndorsement = async (id_endorsement, form) => {
  const data = await instance.put(
    `/policies/update_endosso/${id_endorsement}`,
    form,
  );

  return data;
};

export const deleteEndorsement = async (id_endorsement) => {
  const data = await instance.delete(
    `/policies/delete_endosso/${id_endorsement}`,
  );

  return data;
};

export {
  getIdClient,
  getPartnerByClientId,
  getRelationByPartnerId,
  getRamo,
  getPoliciesPaginate,
  getPerson,
  getPolicyStipuled,
  getPolicyById,
};

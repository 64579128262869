import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";

import argonReact from "../assets/img/icons/common/averbgoColor.png";
import useAuthStore from "../context/Auth/useAuthStore";

const Main = ({ children }) => {
  const { user } = useAuthStore();
  const [sidenavOpen, setSidenavOpen] = useState(false);

  const location = useLocation();
  const mainContentRef = useRef(null);

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }

    setSidenavOpen((old) => !old);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    };
  }, []);

  return (
    <>
      {user.acceptedTerms ? (
        <Sidebar
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: "/main/dashboard",
            imgSrc: argonReact,
            imgAlt: "...",
          }}
        />
      ) : null}
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText="Brand"
        />
        {children}
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

export default Main;

/* eslint-disable eqeqeq */
import { removeSpecialCharacters } from "../helpers/removeSpecialCharacters";
import { formatToBRL, formatToCPFOrCNPJ, isCPFOrCNPJ } from "brazilian-values";

export const TypeDocument = {
  CNPJ: 1,
  CPF: 2,
};

export const maskCEP = (value) => {
  return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");
};

export const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "+$1 $2")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const cnpjMask = (value) => {
  if (!value) return;
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const cpfMask = (value) => {
  if (value.length == 11) {
    return value
      .replace(/\D/g, "")
      .replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4");
  } else {
    return value;
  }
};

export const cnpjCpfMask = (value) => {
  if (value.length < 15) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  if (value.length > 14) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
};

export const formatCurrencyToSend = (value) => {
  return value
    .replace("R$", "")
    .replace(".", "")
    .replace(".", "")
    .replace(",", ".")
    .trim();
};

export const maskReaisNumber = (value = 0) => {
  return formatToBRL(value);
};

export const maskReaisInput = (value = "0.00") => {
  if (value === 0) {
    return "0.00";
  }

  if (value !== null && value !== undefined && value !== 0) {
    if (Number.isInteger(value)) {
      return Number(value.toString().replace(/\D/g, "")).toLocaleString(
        "pt-BR",
        {
          style: "currency",
          currency: "BRL",
        },
      );
    }
    return (Number(value.toString().replace(/\D/g, "")) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      },
    );
  }

  return value;
};

export const MaskDollar = (value) => {
  if (value !== null && value !== undefined && value !== 0) {
    return (Number(value.replace(/\D/g, "")) / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  if (value === 0) {
    return "0.00";
  }

  return value;
};

export const maskOnlyNumbers = (value) => {
  if (value) return value.replace(/\D/g, "");

  return value;
};

export const maskOnlyNumbersToSubimit = (value) => {
  let num = value.split(".").join("").split(",").join(".");

  return Number(num.replace("R$", ""));
};

export const maskOnlyLetters = (value) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

export const maskReaisDigitacao = (value = "0.00") => {
  if (value === 0) {
    return "0.00";
  }

  if (value !== null && value !== undefined && value !== 0) {
    if (Number.isInteger(value)) {
      return Number(value.toString().replace(/\D/g, "")).toLocaleString(
        "pt-BR",
        {
          style: "currency",
          currency: "BRL",
        },
      );
    }
    return (Number(value.toString().replace(/\D/g, "")) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      },
    );
  }

  return value;
};

export const handleMaskByCurrency = (coin, value) => {
  if (coin === "R$" || coin === "Real") {
    return maskReaisInput(value);
  }

  return maskReaisDigitacao(value);
};

export const maskDateFormat = (value) => {
  if (!value) return;
  return value.substring(0, 10).split("-").reverse().join("/");
};

export const maskDateToInput = (value) => {
  if (!value) return;
  return value.substring(0, 10).split("-").join("-");
};

export const formatName = (customerName) => {
  const name = customerName.split(" ");

  return name
    .map((name) => name[0].toUpperCase() + name.substring(1))
    .join(" ");
};

export const verifySearch = (value) => {
  if (value.includes(".") && value.includes("/")) {
    return removeSpecialCharacters(value);
  } else {
    return value;
  }
};

export function splitDateNTime(timestamp) {
  const date = new Date(timestamp);
  const formatDate = date.toISOString().split("T");
  let hora = formatDate[1].split(".").shift();
  let data = formatDate[0];
  return { hora, data };
}

export function formatToCPFOrCNPJLocal(cpfcnpj) {
  if (!cpfcnpj) return "";

  if (!isCPFOrCNPJ(cpfcnpj)) return cpfcnpj;

  return formatToCPFOrCNPJ(cpfcnpj);
}

import axios from "axios";
import {
  getAuthenticatedUser,
  removeAuthenticatedUser,
} from "../localStorage/authentication";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use((config) => {
  const data = getAuthenticatedUser();

  if (config.data) {
    if (config.data.cod_usuario && config.data.cod_usuario !== "") {
      config.data["cod_usuario"] = data?.state.user.cod_usuario;
    }
  }

  if (data.state.user.idToken) {
    config.headers["Authorization"] = data?.state.user.idToken;
    config.headers["des_perfil_sistema"] = data?.state.id_perfil_sistema;
    config.headers["id_usuario_perfil"] = data?.state.id_usuario_perfil;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (typeof error !== "undefined") {
        if (error.response.status === 401) {
          removeAuthenticatedUser();
          window.location = "/login/signin/";
        }
      }
    }

    return Promise.reject(error);
  },
);

export default instance;

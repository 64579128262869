import { useEffect } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import "./styles.css";
import { customStyles } from "./values";

Modal.setAppElement("#root");

function ReactModal({
  title,
  modalIsOpen,
  setIsOpen,
  size = "sm", //  sm | lg | xl | full
  children,
  onCancel,
  onSuccess,
  textBtnOnCancel,
  textBtnOnSuccess,
  actions,
  hasFooter = true,
  hasHeader = true,
  backdrop = true,
}) {
  // const openModal = () => setIsOpen(true)
  const afterOpenModal = () => {};
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (modalIsOpen) {
      window.document.body.style.overflow = "hidden";
    }

    return () => {
      window.document.body.style.overflow = "auto";
    };
  }, [modalIsOpen]);

  const onRequestClose = backdrop ? closeModal : null;

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={title}
    >
      <div className={`wrapperModal ${size}`}>
        {hasHeader && (
          <div className="modalHeader">
            <span className="title">{title || ""}</span>
            <span className="close-modal cursor-pointer" onClick={closeModal}>
              Fechar
            </span>
          </div>
        )}

        <div className="modalBody">{children}</div>

        {hasFooter && (
          <div className="modalFooter">
            {actions ? (
              actions
            ) : (
              <>
                <Button
                  variant="default"
                  type="button"
                  onClick={onCancel || closeModal}
                >
                  {textBtnOnCancel || "Cancelar"}
                </Button>
                {onSuccess && (
                  <Button variant="primary" type="button" onClick={onSuccess}>
                    {textBtnOnSuccess || "Salvar"}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ReactModal;

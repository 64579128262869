import instance from "./axios";

async function getExemptionTypes(setExemptions) {
  try {
    const response = await instance.get(`/get_types/isencao`);

    setExemptions((a) => [
      ...a,
      ...response.data.map((el) => ({
        label: el.des_tipo_isencao,
        value: el.id_tipo_isencao,
      })),
    ]);
  } catch (error) {
    console.error({ error });
  }
}

async function getExemptionsTypes() {
  try {
    const response = await instance.get(`/get_types/isencao`);

    const formatResponse = response.data.map((el) => ({
      label: el.des_tipo_isencao,
      value: el.id_tipo_isencao,
    }));

    return formatResponse;
  } catch (error) {
    console.error({ error });
  }
}

async function getIssuerByRelationId(value) {
  try {
    const response = await instance.get(
      `/client_issuer/all_client_issuer_by_relation_id/${value}`,
    );
    return response.data.map((el) => ({
      label: el.nom_fantasia_emitente,
      value: el.id_pessoa_emitente,
    }));
  } catch (error) {
    if (error.response.status === 404)
      return { error, issuer: error.response.data.error };
    console.error({ error });
  }
}

async function getExemptionsData(value) {
  try {
    const response = await instance.get(`/exemption/get_by_doc/${value}`);
    return response.data;
  } catch (error) {
    console.error({ error });
  }
}

export const createPerson = async (form) => {
  try {
    form.cod_documento_pessoa = form.cod_documento_pessoa.replace(/\D/g, "");
    const response = await instance.post("/person", form);
    return response.data;
  } catch (error) {
    console.error({ error });
  }
};

export const createExemption = async (form) => {
  try {
    const response = await instance.post("/exemption", form);

    return response;
  } catch (error) {
    console.error({ error, function: "Create Exemption" });
  }
};

export const updateExemption = async (id_isencao, form) => {
  try {
    const response = await instance.put(`/exemption/${id_isencao}`, form);
    return response;
  } catch (error) {
    console.error({ error });
  }
};

export const deleteExemption = async (id_isencao) => {
  try {
    const response = await instance.delete(`/exemption/${id_isencao}`, {
      data: { cod_usuario: "Usuario Teste" },
    });

    return response;
  } catch (error) {
    console.error({ error });
  }
};

export const updateExemptionStatus = async ({ id_isencao, tip_status }) => {
  try {
    const response = await instance.put(`/exemption/status/${id_isencao}`, {
      cod_usuario: "teste",
      tip_status: tip_status,
    });
    return response;
  } catch (error) {
    console.error({ error });
  }
};

export const getPersonData = async (value) => {
  try {
    const response = await instance.get(
      `/person/check_cnpj_exists/${value.replace(/\D/g, "")}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllExemptiom = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId,
  relationId,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}`;
  try {
    const response = await instance.get(
      `/exemption/all_exemption_paginate/${queryStringParameters}`,
    );
    return response.data;
  } catch (error) {
    console.error({ error });
  }
};

export const getExemptionById = async (id_isencao) => {
  try {
    const response = await instance.get(
      `/exemption/exemption_by_id/${id_isencao}`,
    );
    return response.data;
  } catch (error) {
    console.warn(error);
    console.error({ error });
  }
};

export const getInsuranceCompanies = async () => {
  try {
    const response = await instance.get(`/get_types/cia_seguradora`);

    const formatedResponse = response.data.map((el) => ({
      label: el.des_cia_seguradora,
      value: el.id_cia_seguradora,
    }));

    return formatedResponse;
  } catch (error) {
    console.error({ error });
  }
};

export const getTypes = async (tableName) => {
  const response = await instance.get(`get_types/${tableName}`);

  const filterResponse = response.data.filter(
    (item) =>
      item.des_tipo_status !== "Pendente" &&
      item.des_tipo_status !== "Bloqueado",
  );

  const formatedResponse = filterResponse.map((el) => ({
    label: el.des_tipo_status,
    value: el.tip_status,
  }));

  return formatedResponse;
};

export {
  getExemptionTypes,
  getIssuerByRelationId,
  getExemptionsData,
  getExemptionsTypes,
};

import { cloneElement } from "react";
import useAuthStore from "../context/Auth/useAuthStore";

function Middleware({ feature, action, children }) {
  const { id_perfil_sistema, json_perfil } = useAuthStore();
  const checkAvailability = (feature, action) => {
    if (json_perfil) {
      return (
        json_perfil[id_perfil_sistema][feature] &&
        json_perfil[id_perfil_sistema][feature][action] === "Ativo"
      );
    }

    return null;
  };

  if (checkAvailability(feature, action)) return cloneElement(children);

  return null;
}

export function ConversorMiddleware({ children }) {
  const { id_perfil_sistema } = useAuthStore();

  if (id_perfil_sistema === "Averbadora") return cloneElement(children);

  return null;
}

export default Middleware;

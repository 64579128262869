import { QueryClient, QueryClientProvider } from "react-query";
import { Confirmation } from "./components/Confirmation";
import Routes from "./routes/";

import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <Confirmation />
      <Routes />
    </QueryClientProvider>
  );
}

export default App;

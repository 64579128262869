import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useConfirmationContext } from "../../context/Confirmation/useConfirmationContext";
import {
  onConfirmChangeIssuerStatus,
  onConfirmDeleteIssuer,
  onConfirmDeleteExtrapolation,
  onConfirmDeleteExemption,
  onConfirmUpdateExemptionStatus,
  onConfirmUpdatePolicyStatus,
  onConfirmDeletePolicy,
  onConfirmDeleteSynonym,
  onConfirmDeleteUser,
  onConfirmDeleteClient,
  onConfirmChangeStatusClient,
  onConfirmChangeStatusRelation,
  onConfirmDeleteRelation,
  onConfirmDeletePartner,
  onConfirmDeletePreDefition,
  onConfirmAcessKey,
  onConfirmEndorsement,
  onDefaultClose,
} from "./callbacks";
import "./index.scss";

export function Confirmation() {
  const { context, isOpen, showConfirmation, contextId, callbacks } =
    useConfirmationContext();
  // const [loading, setloading] = useState(false)

  /* 
    types = 'default'|'info'|'success'|'warning'|'danger'|'error'|'input'|'custom'|'controlled' 
  */

  const data = {
    success: {
      title: "Sucesso",
      caption: "",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Ok",
      type: "success",
      onConfirm: null,
      onCancel: false,
      showCancel: false,
    },

    "extrapolation-delete": {
      title: "Deseja excluir este código?",
      caption: "Atenção, ao confirmar esse código será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteExtrapolation(
          contextId,
          showConfirmation,
          callbacks?.refetch,
        ),
      onCancel: null,
      showCancel: true,
    },

    "user-delete": {
      title: "Deseja excluir este usuário?",
      caption: "Atenção, ao confirmar esse usuário será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteUser(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },

    "issuer-delete": {
      title: "Deseja excluir este emitente?",
      caption: "Atenção, ao confirmar esse emitente será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteIssuer(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "client-delete": {
      title: "Deseja excluir este cliente?",
      caption: "Atenção, ao confirmar esse cliente será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteClient(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "synonym-delete": {
      title: "Deseja excluir esta tag?",
      caption: "Atenção, ao confirmar essa tag será excluída!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteSynonym(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "relation-delete": {
      title: "Deseja excluir este segurado?",
      caption: "Atenção, ao confirmar esse segurado será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteRelation(
          contextId,
          showConfirmation,
          callbacks?.refetch,
        ),
      onCancel: null,
      showCancel: true,
    },
    "change-relation-status": {
      title: "Alterar status do segurado",
      caption: "Você tem certeza que deseja alterar o status do segurado?",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Alterar",
      type: "warning",
      onConfirm: () =>
        onConfirmChangeStatusRelation(
          contextId,
          showConfirmation,
          callbacks?.refetch,
          callbacks?.newStatusType,
        ),
      onCancel: null,
      showCancel: true,
    },
    "change-client-status": {
      title: "Alterar status Cliente",
      caption: "Você tem certeza que deseja alterar o status do cliente?",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Alterar",
      type: "warning",
      onConfirm: () =>
        onConfirmChangeStatusClient(
          contextId,
          showConfirmation,
          callbacks?.refetch,
          callbacks?.newStatusType,
        ),
      onCancel: null,
      showCancel: true,
    },
    "change-issuer-status": {
      title: "Alterar status Emissor",
      caption: "Você tem certeza que deseja alterar o status do emissor?",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Alterar",
      type: "warning",
      onConfirm: () =>
        onConfirmChangeIssuerStatus(
          contextId,
          showConfirmation,
          callbacks?.refetch,
          callbacks?.newStatusType,
        ),
      onCancel: null,
      showCancel: true,
    },
    "change-partner-status": {
      title: "Alterar status Parceiro",
      caption: "Você tem certeza que deseja alterar o status do parceiro?",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Alterar",
      type: "warning",
      onConfirm: () =>
        alert(
          contextId,
          showConfirmation,
          callbacks?.refetch,
          callbacks?.newStatusType,
        ),
      onCancel: null,
      showCancel: true,
    },
    "partner-delete": {
      title: "Deseja excluir este parceiro?",
      caption: "Atenção, ao confirmar esse parceiro será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeletePartner(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "exemption-delete": {
      title: "Deseja excluir esta isenção?",
      caption: "Atenção, ao confirmar essa isenção será excluída!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeleteExemption(
          contextId,
          showConfirmation,
          callbacks?.refetch,
        ),
      onCancel: null,
      showCancel: true,
    },
    "exemption-update-status": {
      title: "Alterar status desta isenção?",
      caption: "Atenção ...",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Alterar",
      type: "warning",
      onConfirm: () =>
        onConfirmUpdateExemptionStatus(
          contextId,
          showConfirmation,
          callbacks?.refetch,
        ),
      onCancel: null,
      showCancel: true,
    },
    "policy-update-status": {
      title: "Alterar status desta Apólice?",
      caption: "Atenção ...",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Alterar",
      type: "warning",
      onConfirm: () =>
        onConfirmUpdatePolicyStatus(
          contextId,
          showConfirmation,
          callbacks?.refetch,
          callbacks?.newStatusType,
        ),
      onCancel: null,
      showCancel: true,
    },
    "policy-delete-status": {
      title: "Deseja excluir esta Apólice?",
      caption: "Atenção, ao confirmar essa apólice será excluída!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeletePolicy(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "pre-denition-delete": {
      title: "Deseja excluir este molde pré-definido?",
      caption: "Atenção, ao confirmar este molde pré-definido será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmDeletePreDefition(
          contextId,
          showConfirmation,
          callbacks?.refetch,
          callbacks?.resetIds,
        ),
      onCancel: null,
      showCancel: true,
    },
    "key-delete": {
      title: "Deseja excluir esta chave de acesso?",
      caption: "Atenção, ao confirmar essa chave de acesso será excluída!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmAcessKey(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "endorment-delete": {
      title: "Deseja excluir este endosso?",
      caption: "Atenção, ao confirmar esse endosso será excluído!",
      cancelBtnText: "Cancelar",
      confirmBtnText: "Excluir",
      type: "warning",
      onConfirm: () =>
        onConfirmEndorsement(contextId, showConfirmation, callbacks?.refetch),
      onCancel: null,
      showCancel: true,
    },
    "export-all-records-excel": {
      title: "Exportação de excel",
      caption: "Deseja exportar todos os dados ou somente a pagina atual ?",
      cancelBtnText: "Atual",
      confirmBtnText: "Todos",
      type: "info",
      onConfirm: async () =>
        onDefaultClose(callbacks?.allRecords, showConfirmation),
      onCancel: async () =>
        onDefaultClose(callbacks?.onlyCurrentPage, showConfirmation),
      showCancel: true,
      allowEscape: false,
    },
  };

  if (context) {
    return (
      <ReactBSAlert
        show={isOpen}
        type={data[context].type}
        title={data[context].title}
        onConfirm={
          data[context].onConfirm !== undefined && data[context].onConfirm
            ? data[context].onConfirm
            : () => showConfirmation("")
        }
        onCancel={
          data[context].onCancel !== undefined && data[context].onCancel
            ? data[context].onCancel
            : () => showConfirmation("")
        }
        confirmBtnBsStyle="default"
        confirmBtnText={data[context].confirmBtnText}
        cancelBtnText={data[context].cancelBtnText}
        cancelBtnBsStyle="true"
        showCancel={data[context].showCancel}
        customClass="sweetAlert-modal"
        allowEscape={data[context].allowEscape ?? true}
        closeOnClickOutside={data[context].allowEscape ?? true}
      >
        <div className="d-flex flex-column">
          <h5
            style={{
              wordBreak: "break-all !important",
              whiteSpace: "break-spaces",
            }}
          >
            {data[context].caption}
          </h5>
        </div>
      </ReactBSAlert>
    );
  }

  return null;
}

import instance from "./axios";

export const getPartner = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus = 0,
  customerId,
) => {
  const data = await instance.get(
    `/client_partner/all_client_partner_paginate/${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}`,
  );

  return data;
};

export const getPessoaParceiroById = async (idPessoaParceiro) => {
  const data = await instance.get(`/client_partner/get_id/${idPessoaParceiro}`);

  return data;
};

export const postClientPartner = async (body) => {
  const data = await instance.post("/client_partner", body);

  return data;
};

export const updateClientPartner = async (body) => {
  const data = await instance.put(
    `/client_partner/${body.id_pessoa_parceiro}`,
    body,
  );

  return data;
};

export const deleteClientPartner = async (idPessoa) => {
  const data = await instance.delete(`/client_partner/${idPessoa}`, {
    data: {
      cod_usuario: "teste",
    },
  });

  return data;
};

export const clientsByType = async (
  cliente,
  searchData,
  currentPage = 1,
  limit,
) => {
  const all = cliente.todos ? "todos" : "";
  const formated = JSON.stringify(cliente.data);
  const { data } = await instance.get(
    `/clients_by_type/parceiro?page_number=${currentPage}&limit=${limit}&search_data=${searchData}&todos=${all}&ids=${formated}`,
  );

  return data;
};

export const getPartnerOptions = async (docPartner, customerId) => {
  const { data } = await instance.get(
    `/client_issuer/authorization/parceiro?search_data=${docPartner}&id_pessoa_cliente=${customerId}`,
  );

  return data;
};

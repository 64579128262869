import classnames from "classnames";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  clientIco,
  conversionIco,
  xmlIco,
} from "../../assets/img/bootstrapSvgIcons";

import Middleware, { ConversorMiddleware } from "../../Middleware";

function Sidebar({ toggleSidenav, sidenavOpen, logo, rtlActive }) {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (document.body.classList.contains("g-sidenav-pinned") && sidenavOpen) {
      setShowText(true);
    }
  }, [sidenavOpen]);

  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
      setShowText(true);
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
      setShowText(false);
    }
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const scrollBarInner = (
    <div className="scrollbar-inner" style={{ background: "#F7FAFC" }}>
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen>
          <hr className="my-3" />
          <Nav className="mb-md-3" navbar>
            <Middleware feature="Dashboard" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/dashboard">
                  <i className="ni ni-shop text-blue" />
                  {showText && <span className="nav-link-text">Dashboard</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Cliente" action="Consulta">
              <NavItem>
                <NavLink href="/main/clientes">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person text-red"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                    </svg>
                  </i>
                  {showText && <span className="nav-link-text">Clientes</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Parceiro" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/parceiros">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-people text-blue"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                    </svg>
                  </i>
                  {showText && <span className="nav-link-text">Parceiros</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Segurado" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/segurado">
                  <i className="ni ni-circle-08 text-green" />
                  {showText && <span className="nav-link-text">Segurados</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Emitente" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/emitente">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-lines-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                    </svg>
                  </i>
                  {showText && <span className="nav-link-text">Emitentes</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Apólice" action="Consulta">
              <NavItem>
                <NavLink href="/main/apolices">
                  <i className="ni ni-ui-04 text-info" />
                  {showText && <span className="nav-link-text">Apólices</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Isenção" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/isencoes">
                  <i className="ni ni-archive-2 text-green" />
                  {showText && <span className="nav-link-text">Isenções</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Averbação" action={"Inclusão"}>
              <NavItem>
                <NavLink href="/endorsement">
                  <i className="ni ni-single-copy-04 text-pink" />
                  {showText && (
                    <span className="nav-link-text">Averbações</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Consulta Averbação" action="Consulta">
              <NavItem>
                <NavLink href="/main/averbacoes">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search text-red"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </i>
                  {showText && (
                    <span className="nav-link-text">Consulta averbações</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>
            <Middleware feature="Consulta MDFe" action="Consulta">
              <NavItem>
                <NavLink href="/main/manifesto">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search text-black"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </i>
                  {showText && (
                    <span className="nav-link-text">Consulta MDF-e</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Recusas" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/recusados">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-file-earmark-excel text-yellow"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    </svg>
                  </i>
                  {showText && (
                    <span className="nav-link-text">Documentos recusados</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>

            <ConversorMiddleware>
              <NavItem>
                <NavLink href="/main/conversor-arquivos">
                  <i className="text-green">{xmlIco()}</i>
                  {showText && (
                    <span className="nav-link-text">Conversor de arquivos</span>
                  )}
                </NavLink>
              </NavItem>
            </ConversorMiddleware>

            <ConversorMiddleware>
              <NavItem>
                <NavLink href="/main/sinonimos-de-cidades">
                  <i className="text-green">{conversionIco()}</i>
                  {showText && (
                    <span className="nav-link-text">Sinônimos de cidades</span>
                  )}
                </NavLink>
              </NavItem>
            </ConversorMiddleware>

            <Middleware feature="Histórico" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/historico">
                  {clientIco()}
                  {showText && <span className="nav-link-text">Histórico</span>}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Chave de Acesso" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/chaves">
                  <i className="ni ni-map-big text-blue" />
                  {showText && (
                    <span className="nav-link-text">Chave de acesso</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Gestão de Usuários" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/usuarios">
                  <i className="ni ni-chart-pie-35 text-cyan" />
                  {showText && (
                    <span className="nav-link-text">Gerenciar usuários</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Sinônimos" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/sinonimos">
                  <i className="ni ni-palette" />
                  {showText && (
                    <span className="nav-link-text">Cadastro de sinônimos</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>

            <Middleware feature="Cód Liberação" action={"Consulta"}>
              <NavItem>
                <NavLink href="/main/codigo-liberacao">
                  <i className="ni ni-ui-04 text-info" />
                  {showText && (
                    <span className="nav-link-text">Código de liberação</span>
                  )}
                </NavLink>
              </NavItem>
            </Middleware>
          </Nav>
        </Collapse>
      </div>
    </div>
  );

  return (
    <Navbar
      className={`sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ${
        rtlActive ? "" : "fixed-left"
      }`}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
  rtlActive: PropTypes.bool,
};

export default Sidebar;

const aplicationName = process.env.REACT_APP_APPLICATION_KEY;

export const setAuthenticatedUser = (tokenJwt) =>
  localStorage.setItem(aplicationName, tokenJwt);

export const getAuthenticatedUser = () =>
  JSON.parse(localStorage.getItem(aplicationName));

export const removeAuthenticatedUser = () =>
  localStorage.removeItem(aplicationName);

import { formatCurrencyToSend } from "../utils";
import { checkValue } from "../utils/checkValue";
import instance from "./axios";

export const getAllExtrapolation = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId,
  relationId,
  issuerId,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}&id_pessoa_emitente=${issuerId}`;
  const data = await instance.get(
    `/extrapolation/all_extrapolation_lmg_paginate/${queryStringParameters}`,
  );

  return data;
};

export const getExtrapolationByIdExtrapolacaoLmg = async (
  idExtrapolacaoLmg,
) => {
  const data = await instance.get(`/extrapolation/get_id/${idExtrapolacaoLmg}`);

  return data;
};

export const deleteExtrapolation = async (idExtrapolacaoLmg) => {
  const data = await instance.delete(`/extrapolation/${idExtrapolacaoLmg}`, {
    data: {
      cod_usuario: "teste",
    },
  });

  return data;
};

export const updateExtrapolation = async (idExtrapolacaoLmg, values) => {
  const manipulatedDataUpdate = {
    ...values,
    documento_especifico: values.documento_especifico,
    num_documento: values.documento_especifico ? values.num_documento : null,
    num_serie_documento: values.documento_especifico
      ? values.num_serie_documento
      : null,
    dta_validade: values.dta_validade || null,
    vlr_limite: checkValue(values.vlr_limite) || null,
    qtd_uso: values.qtd_uso || null,
    tip_status: values.tip_status || null,
  };

  const data = await instance.put(
    `/extrapolation/${idExtrapolacaoLmg}`,
    manipulatedDataUpdate,
  );

  return data;
};

export const createExtrapolation = async (values) => {
  const manipulatedData = {
    ...values,
    // documento_especifico: values.documento_especifico || null,
    dta_validade: values.dta_validade || null,
    num_documento: values.documento_especifico ? values.num_documento : null,
    num_serie_documento: values.documento_especifico
      ? values.num_serie_documento
      : null,
    vlr_limite: formatCurrencyToSend(values.vlr_limite) || null,
    qtd_uso: values.qtd_uso || null,
  };
  const data = await instance.post("/extrapolation", manipulatedData);

  return data;
};

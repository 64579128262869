import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css";

import "./index.css";
import "./index.scss";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "./assets/vendor/nucleo/css/nucleo.css";

import "../src/assets/vendor/nucleo/css/nucleo.css";

import App from "./App";
import "./i18n";

import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { ConfirmationProvider } from "./context/Confirmation/context";
import { GlobalProvider } from "./hooks/global";

const history = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter
    basename={process.env.PUBLIC_URL}
    forceRefresh={true}
    history={history}
  >
    <GlobalProvider history={history}>
      <ConfirmationProvider>
        <App />
      </ConfirmationProvider>
    </GlobalProvider>
  </BrowserRouter>,

  document.getElementById("root"),
);

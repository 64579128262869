import { deleteExtrapolation } from "../../services/extrapolation";
import { deleteClientIssuer, updateStatusIssuer } from "../../services/issuer";
import {
  deleteExemption,
  updateExemptionStatus,
} from "../../services/exemption";
import {
  deleteEndorsement,
  deletePolicy,
  updatePolicyStatus,
} from "../../services/policy";
import { deleteSynonym } from "../../services/synonym";
import { deleteUser } from "../../services/user";
import { deleteClient, updateStatusClient } from "../../services/person";
import { deleteRelation, updateStatusRelation } from "../../services/relation";
import { deleteClientPartner } from "../../services/partner";
import { deletePreDefinition } from "../../services/preDefinition";
import { deleteKey } from "../../services/keys";

export const onConfirmEndorsement = async (id, showConfirmation, refetch) => {
  deleteEndorsement(id)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmChangeIssuerStatus = async (
  idPessoaEmitente,
  showConfirmation,
  refetch,
  newStatusType,
) => {
  updateStatusIssuer(idPessoaEmitente, newStatusType)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmChangeStatusRelation = async (
  id_pessoa_cliente,
  showConfirmation,
  refetch,
  newStatusType,
) => {
  updateStatusRelation(id_pessoa_cliente, newStatusType)
    .then(({ data }) => {
      showConfirmation("");
      refetch();
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmChangeStatusClient = async (
  id_pessoa_cliente,
  showConfirmation,
  refetch,
  newStatusType,
) => {
  updateStatusClient(id_pessoa_cliente, newStatusType)
    .then(({ data }) => {
      showConfirmation("");
      refetch();
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmDeleteClient = async (
  id_pessoa_cliente,
  showConfirmation,
  refetch,
) => {
  deleteClient(id_pessoa_cliente)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmDeleteRelation = async (
  id,
  showConfirmation,
  refetch,
) => {
  deleteRelation(id)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmDeleteIssuer = async (
  idPessoaEmitente,
  showConfirmation,
  refetch,
) => {
  deleteClientIssuer(idPessoaEmitente)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmDeletePartner = async (
  idPessoaPartner,
  showConfirmation,
  refetch,
) => {
  deleteClientPartner(idPessoaPartner)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmDeleteExtrapolation = async (
  idExtrapolacaoLmg,
  showConfirmation,
  refetch,
) => {
  deleteExtrapolation(idExtrapolacaoLmg)
    .then(({ data }) => {
      refetch();
      showConfirmation("");
    })
    .catch((error) => {
      console.error("then", error);
    })
    .finally(() => {});
};

export const onConfirmDeleteExemption = async (
  id_isencao,
  showConfirmation,
  refetch,
) => {
  try {
    await deleteExemption(id_isencao);

    refetch();
    showConfirmation("");
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const onConfirmDeleteSynonym = async (
  id_isencao,
  showConfirmation,
  refetch,
) => {
  try {
    await deleteSynonym(id_isencao);

    refetch();
    showConfirmation("");
  } catch (error) {
    console.error(error);
  } finally {
  }
};

export const onConfirmUpdateExemptionStatus = async (
  id_isencao,
  showConfirmation,
  refetch,
) => {
  try {
    await updateExemptionStatus(id_isencao);

    refetch();
    showConfirmation("");
  } catch (error) {
    console.error(error);
  } finally {
    // console.warn("FINISH");
  }
};

export const onConfirmDeletePolicy = async (
  id_isencao,
  showConfirmation,
  refetch,
) => {
  try {
    await deletePolicy(id_isencao);
    refetch();
    showConfirmation("");
  } catch (error) {
    console.error(error);
  } finally {
    // console.warn("FINISH");
  }
};

export const onConfirmUpdatePolicyStatus = async (
  id_isencao,
  showConfirmation,
  refetch,
  newStatusType,
) => {
  try {
    await updatePolicyStatus(id_isencao, newStatusType);
    refetch();
    showConfirmation("");
  } catch (error) {
    console.error(error);
  } finally {
    // console.warn("FINISH");
  }
};

export const onConfirmDeleteUser = async (id, showConfirmation, refetch) => {
  try {
    await deleteUser(id);
    refetch();
    showConfirmation("");
  } catch (e) {
    console.error("then", e);
  } finally {
  }
};

export const onConfirmDeletePreDefition = async (
  id,
  showConfirmation,
  refecth,
  resetIds,
) => {
  try {
    await deletePreDefinition(id);
    resetIds();
    refecth();
    showConfirmation("");
  } catch (error) {
    console.error("then", error);
  } finally {
  }
};

export const onConfirmAcessKey = async (id, showConfirmation, refecth) => {
  try {
    await deleteKey(id);
    refecth();
    showConfirmation("");
  } catch (error) {
    console.error("then", error);
  } finally {
  }
};

export const onDefaultClose = async (calback, showConfirmation) => {
  try {
    await calback();
    showConfirmation("");
  } catch (error) {
    console.error("then", error);
  } finally {
  }
};

import instance from "./axios";

export const createPreDefiniton = async (form) => {
  const data = await instance.post("/pre_defined_fields", form);

  return data;
};

export const getAllPreDefinition = async (page_number = 1, limit = 20) => {
  const data = await instance.get(
    `/pre_defined_fields/all_paginate/${page_number}?limit=${limit}`,
  );

  return data;
};

export const getPreDefinitionById = async (id) => {
  const data = await instance.get(`/pre_defined_fields/by_id/${id}`);

  return data;
};

export const updatePreDefinition = async (id, form) => {
  const data = await instance.put(`/pre_defined_fields/${id}`, form);

  return data;
};

export const deletePreDefinition = async (ids) => {
  const data = await instance.delete(
    `/pre_defined_fields/deleteMany/?ids_pre_definido=${JSON.stringify(ids)}`,
  );

  return data;
};

import { Form, Formik, Field, ErrorMessage } from "formik";
import { Row, Col, CardBody, Input, Button } from "reactstrap";

import FeatureSelectFactory from "../../../pages/Login/components/Signin/components/FeatureSelect/FeatureSelectFactory";
import { loginProfileValidationSchema } from "../../../pages/Login/validationSchema";

import FieldTextError from "../../FieldTextError";
import IsLoading from "../../IsLoading";

function FeatureSelect({ togglerModal }) {
  const { profiles, loading, isLoading, isError, onSubmit } =
    FeatureSelectFactory();

  if (isLoading) return <IsLoading />;
  if (isError) {
    return (
      <Row>
        <Col className="text-center p-5">
          <i className="ni ni-box-2"></i>
          <p className="text-muted">
            Algo deu errado, tente novamente mais tarde.
          </p>
        </Col>
      </Row>
    );
  }

  return (
    <Formik
      initialValues={{ name_profile_user: "" }}
      validationSchema={loginProfileValidationSchema}
      onSubmit={(values) => onSubmit(values, true, togglerModal)}
    >
      {({ values, touched, handleChange, errors }) => (
        <Form>
          <CardBody>
            <Row>
              <Col className="col-12">
                <label
                  className="form-control-label"
                  htmlFor="name_profile_user"
                >
                  Selecione um perfil
                </label>
                <Field
                  type="select"
                  required
                  component={Input}
                  id="name_profile_user"
                  name="name_profile_user"
                  invalid={
                    touched.name_profile_user && errors.name_profile_user
                  }
                  onChange={handleChange}
                  value={values.name_profile_user}
                >
                  <option value="">Selecione</option>
                  {profiles.map((row) => (
                    <option value={row}>{row}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="name_profile_user"
                  component={FieldTextError}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="col-12">
                <Button type="submit" color="primary" block disabled={loading}>
                  {loading ? "...Alterar" : "Alterar"}
                </Button>
              </Col>
              <Col className="col-12 mt-4 d-flex justify-content-center">
                <Button
                  type="button"
                  color=""
                  onClick={togglerModal}
                  disabled={loading}
                >
                  {" "}
                  Cancelar{" "}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Form>
      )}
    </Formik>
  );
}

export default FeatureSelect;

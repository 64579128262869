import classnames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Collapse,
  Navbar,
  NavItem,
  Nav,
  Container,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import useAuthStore from "../../context/Auth/useAuthStore";
import FeatureSelectFactory from "../../pages/Login/components/Signin/components/FeatureSelect/FeatureSelectFactory";

import ReactModal from "../ReactModal";
import FeatureSelect from "./components/FeatureSelect";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const { logout, id_perfil_sistema, user } = useAuthStore();
  const [isOpen, setIsopen] = useState(false);
  const togglerModal = () => setIsopen(!isOpen);

  const { profiles, isLoading } = FeatureSelectFactory();

  return (
    <>
      <ReactModal
        modalIsOpen={isOpen}
        setIsOpen={togglerModal}
        size="sm"
        hasFooter={false}
        hasHeader={false}
        backdrop
      >
        <FeatureSelect togglerModal={togglerModal} />
      </ReactModal>

      <Navbar
        style={{ background: "#2255F9", padding: "9px" }}
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark": theme === "dark" },
          { "navbar-light": theme === "light" },
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            {isLoading && (
              <span className="text-white text-sm">...Carregando pefil</span>
            )}
            {profiles?.length > 1 && (
              <>
                <span
                  className="text-white mr-2 text-right"
                  style={{ fontSize: "10px" }}
                >
                  Logado como <br />
                  <strong>{id_perfil_sistema}</strong>
                </span>
                <button
                  className="btn btn-outline-info btn-sm"
                  onClick={togglerModal}
                >
                  Alterar Perfil
                </button>
              </>
            )}
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" },
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none"></NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <span className="text-white mr-2 d-none d-md-block">
                {user.full_name}
              </span>
              <PaginationItem>
                <PaginationLink onClick={() => logout()}>
                  <i className="ni ni-user-run text-red" />
                </PaginationLink>
              </PaginationItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;

import React from "react";

export const likeIco = () => (
  <div className="bg-transparent" style={{ padding: "1em" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-hand-thumbs-up"
      viewBox="0 0 16 16"
    >
      <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
    </svg>
  </div>
);

export const clientIco = () => (
  <i>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-clock-history text-blue"
      viewBox="0 0 16 16"
    >
      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
    </svg>
  </i>
);

export const userIco = () => (
  <i>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-person"
      viewBox="0 0 16 16"
    >
      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
    </svg>
  </i>
);

export const pdfIco = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0759 1.55371L22.2489 5.90371V22.4457H6.66016V22.4997H22.3022V5.95846L18.0759 1.55371Z"
      fill="#909090"
    />
    <path
      d="M18.0227 1.5H6.60547V22.446H22.2475V5.90475L18.022 1.5"
      fill="#F4F4F4"
    />
    <path
      d="M6.49172 2.625H1.69922V7.74525H16.7742V2.625H6.49172Z"
      fill="#7A7B7C"
    />
    <path
      d="M16.8546 7.65818H1.79688V2.53418H16.8546V7.65818Z"
      fill="#DD2025"
    />
    <path
      d="M6.78884 3.40003H5.80859V7.00003H6.57959V5.78578L6.74984 5.79553C6.91527 5.79268 7.07915 5.76305 7.23509 5.70778C7.37182 5.66075 7.4976 5.58651 7.60484 5.48953C7.71397 5.39714 7.8 5.28055 7.85609 5.14903C7.93132 4.9304 7.95818 4.69805 7.93484 4.46803C7.93016 4.30371 7.90135 4.14097 7.84934 3.98503C7.802 3.87246 7.73174 3.77098 7.64303 3.68705C7.55432 3.60312 7.44911 3.53857 7.33409 3.49753C7.23465 3.46153 7.13191 3.4354 7.02734 3.41953C6.94816 3.40731 6.86821 3.40079 6.78809 3.40003H6.78884ZM6.64634 5.12053H6.57959V4.01053H6.72434C6.78823 4.00592 6.85233 4.01573 6.91192 4.03922C6.9715 4.06272 7.02505 4.09931 7.06859 4.14628C7.15883 4.26704 7.20704 4.41403 7.20584 4.56478C7.20584 4.74928 7.20584 4.91653 7.03934 5.03428C6.91939 5.10025 6.78286 5.12996 6.64634 5.11978V5.12053ZM9.39959 3.39028C9.31634 3.39028 9.23534 3.39628 9.17834 3.39853L8.99984 3.40303H8.41484V7.00303H9.10334C9.36646 7.01024 9.62843 6.96564 9.87434 6.87178C10.0723 6.79327 10.2476 6.66671 10.3843 6.50353C10.5174 6.33887 10.6128 6.14716 10.6641 5.94178C10.723 5.70918 10.7518 5.46996 10.7496 5.23003C10.7641 4.94665 10.7422 4.66257 10.6843 4.38478C10.6294 4.1803 10.5266 3.99185 10.3843 3.83503C10.2727 3.7084 10.1361 3.60626 9.98309 3.53503C9.85168 3.47422 9.71344 3.42939 9.57134 3.40153C9.51484 3.39219 9.45761 3.38793 9.40034 3.38878L9.39959 3.39028ZM9.26384 6.34153H9.18884V4.04353H9.19859C9.35321 4.02574 9.50966 4.05364 9.64859 4.12378C9.75034 4.20502 9.83325 4.30738 9.89159 4.42378C9.95456 4.54628 9.99086 4.68073 9.99809 4.81828C10.0048 4.98328 9.99809 5.11828 9.99809 5.23003C10.0011 5.35876 9.99287 5.4875 9.97334 5.61478C9.95023 5.74545 9.9075 5.87188 9.84659 5.98978C9.77766 6.09939 9.68453 6.19176 9.57434 6.25978C9.48181 6.31963 9.37223 6.34755 9.26234 6.33928L9.26384 6.34153ZM13.0723 3.40303H11.2498V7.00303H12.0208V5.57503H12.9958V4.90603H12.0208V4.07203H13.0708V3.40303"
      fill="#464648"
    />
    <path
      d="M16.3365 15.1911C16.3365 15.1911 18.7275 14.7576 18.7275 15.5744C18.7275 16.3911 17.2462 16.0589 16.3365 15.1911ZM14.5687 15.2534C14.1889 15.3373 13.8187 15.4602 13.464 15.6201L13.764 14.9451C14.064 14.2701 14.3752 13.3499 14.3752 13.3499C14.7332 13.9524 15.1497 14.5181 15.6187 15.0389C15.2651 15.0916 14.9145 15.1637 14.5687 15.2549V15.2534ZM13.6222 10.3784C13.6222 9.66664 13.8525 9.47239 14.0317 9.47239C14.211 9.47239 14.4127 9.55864 14.4195 10.1766C14.3611 10.7981 14.231 11.4106 14.0317 12.0021C13.7588 11.5055 13.6176 10.9473 13.6215 10.3806L13.6222 10.3784ZM10.1355 18.2654C9.40199 17.8266 11.6737 16.4759 12.0855 16.4324C12.0832 16.4331 10.9035 18.7244 10.1355 18.2654ZM19.4257 15.6711C19.4182 15.5961 19.3507 14.7659 17.8732 14.8011C17.2574 14.7912 16.6419 14.8346 16.0335 14.9309C15.4442 14.3372 14.9367 13.6675 14.5245 12.9396C14.7842 12.1892 14.9414 11.4071 14.9917 10.6146C14.97 9.71464 14.7547 9.19864 14.0647 9.20614C13.3747 9.21364 13.2742 9.81739 13.365 10.7159C13.4539 11.3197 13.6216 11.9092 13.8637 12.4694C13.8637 12.4694 13.545 13.4616 13.1235 14.4486C12.702 15.4356 12.414 15.9531 12.414 15.9531C11.681 16.1917 10.991 16.5462 10.3702 17.0031C9.75225 17.5784 9.501 18.0201 9.8265 18.4619C10.107 18.8429 11.0887 18.9291 11.9662 17.7794C12.4325 17.1855 12.8584 16.5611 13.2412 15.9104C13.2412 15.9104 14.5792 15.5436 14.9955 15.4431C15.4117 15.3426 15.915 15.2631 15.915 15.2631C15.915 15.2631 17.1367 16.4924 18.315 16.4489C19.4932 16.4054 19.4362 15.7446 19.4287 15.6726"
      fill="#DD2025"
    />
    <path
      d="M17.9648 1.55762V5.96237H22.1896L17.9648 1.55762Z"
      fill="#909090"
    />
    <path d="M18.0234 1.5V5.90475H22.2482L18.0234 1.5Z" fill="#F4F4F4" />
    <path
      d="M6.73025 3.34241H5.75V6.94241H6.524V5.72891L6.695 5.73866C6.86043 5.73581 7.0243 5.70618 7.18025 5.65091C7.31697 5.60387 7.44275 5.52962 7.55 5.43266C7.65831 5.34003 7.74356 5.22345 7.799 5.09216C7.87422 4.87353 7.90109 4.64119 7.87775 4.41116C7.87307 4.24684 7.84426 4.0841 7.79225 3.92816C7.74491 3.81559 7.67464 3.71411 7.58593 3.63018C7.49723 3.54625 7.39202 3.48171 7.277 3.44066C7.1771 3.40431 7.07385 3.37793 6.96875 3.36191C6.88957 3.34969 6.80961 3.34318 6.7295 3.34241H6.73025ZM6.58775 5.06291H6.521V3.95291H6.6665C6.73039 3.9483 6.79449 3.95811 6.85407 3.98161C6.91366 4.0051 6.9672 4.04169 7.01075 4.08866C7.10099 4.20942 7.1492 4.35641 7.148 4.50716C7.148 4.69166 7.148 4.85891 6.9815 4.97666C6.86155 5.04263 6.72502 5.07234 6.5885 5.06216L6.58775 5.06291ZM9.341 3.33266C9.25775 3.33266 9.17675 3.33866 9.11975 3.34091L8.9435 3.34541H8.3585V6.94541H9.047C9.31012 6.95262 9.57209 6.90803 9.818 6.81416C10.0159 6.73565 10.1912 6.6091 10.328 6.44591C10.461 6.28125 10.5565 6.08954 10.6077 5.88416C10.6667 5.65156 10.6954 5.41235 10.6932 5.17241C10.7078 4.88903 10.6859 4.60495 10.628 4.32716C10.5731 4.12268 10.4702 3.93423 10.328 3.77741C10.2164 3.65078 10.0798 3.54864 9.92675 3.47741C9.79534 3.4166 9.6571 3.37178 9.515 3.34391C9.45849 3.33458 9.40127 3.33031 9.344 3.33116L9.341 3.33266ZM9.2075 6.28391H9.1325V3.98591H9.14225C9.29687 3.96812 9.45331 3.99602 9.59225 4.06616C9.694 4.14741 9.7769 4.24976 9.83525 4.36616C9.89822 4.48866 9.93451 4.62311 9.94175 4.76066C9.9485 4.92566 9.94175 5.06066 9.94175 5.17241C9.9448 5.30114 9.93652 5.42989 9.917 5.55716C9.89388 5.68783 9.85115 5.81426 9.79025 5.93216C9.72132 6.04178 9.62819 6.13414 9.518 6.20216C9.42547 6.26201 9.31589 6.28993 9.206 6.28166L9.2075 6.28391ZM13.0138 3.34541H11.1912V6.94541H11.9622V5.51741H12.9372V4.84841H11.9622V4.01441H13.0122V3.34541"
      fill="white"
    />
  </svg>
);

export const excelIco = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8875 2.89069H12.2397V1.32422L1.3125 3.01078V17.7941L12.2397 19.6763V17.3544H18.8875C19.0889 17.3646 19.286 17.2947 19.436 17.1599C19.5859 17.0251 19.6763 16.8365 19.6875 16.6352V3.60928C19.6761 3.40812 19.5856 3.21962 19.4357 3.08498C19.2858 2.95033 19.0888 2.88048 18.8875 2.89069ZM18.9925 16.7546H12.2174L12.2063 15.515H13.8383V14.0712H12.1938L12.1859 13.2181H13.8383V11.7743H12.1734L12.1656 10.9212H13.8383V9.47747H12.1603V8.62434H13.8383V7.18059H12.1603V6.32747H13.8383V4.88372H12.1603V3.57122H18.9925V16.7546Z"
      fill="#20744A"
    />
    <path
      d="M14.7578 4.88184H17.5948V6.32559H14.7578V4.88184ZM14.7578 7.17937H17.5948V8.62312H14.7578V7.17937ZM14.7578 9.4769H17.5948V10.9206H14.7578V9.4769ZM14.7578 11.7744H17.5948V13.2182H14.7578V11.7744ZM14.7578 14.072H17.5948V15.5157H14.7578V14.072Z"
      fill="#20744A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16372 7.00403L5.57203 6.92331L6.45731 9.35734L7.50337 6.82291L8.91169 6.74219L7.2015 10.198L8.91169 13.6623L7.42266 13.5619L6.41728 10.9212L5.41125 13.4615L4.04297 13.3408L5.63241 10.2807L4.16372 7.00403Z"
      fill="white"
    />
  </svg>
);

export const issuerIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-person-lines-fill"
    viewBox="0 0 16 16"
  >
    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
  </svg>
);

export const xmlIco = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
    <g strokeWidth="0"></g>
    <g strokeLinecap="round" strokeLinejoin="round"></g>
    <g>
      {" "}
      <path
        d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M15.1992 11.5V14.5H12.1992M13.6992 17.2361C13.1685 17.7111 12.4676 18 11.6992 18C10.0424 18 8.69922 16.6569 8.69922 15C8.69922 13.3431 10.0424 12 11.6992 12C13.0054 12 14.1167 12.8348 14.5285 14"
        stroke="currentColor"
        strokeWidth="1.224"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{" "}
    </g>
  </svg>
);

export const conversionIco = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7 4.44995L17.6799 6.74994L21.6199 4.45996"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6799 10.82V6.73999"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.74 2.21L14.34 3.53996C13.8 3.83996 13.35 4.59995 13.35 5.21995V7.75999C13.35 8.37999 13.79 9.13998 14.34 9.43998L16.74 10.77C17.25 11.06 18.09 11.06 18.61 10.77L21.01 9.43998C21.55 9.13998 22 8.37999 22 7.75999V5.21995C22 4.59995 21.56 3.83996 21.01 3.53996L18.61 2.21C18.1 1.93 17.26 1.93 16.74 2.21Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.34998 15.45L6.31998 17.7499L10.27 15.46"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.31995 21.82V17.74"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.39 13.21L2.99001 14.54C2.45001 14.84 2 15.5999 2 16.2199V18.76C2 19.38 2.44001 20.14 2.99001 20.44L5.39 21.77C5.9 22.06 6.73999 22.06 7.25999 21.77L9.66 20.44C10.2 20.14 10.65 19.38 10.65 18.76V16.2199C10.65 15.5999 10.21 14.84 9.66 14.54L7.25999 13.21C6.73999 12.93 5.9 12.93 5.39 13.21Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

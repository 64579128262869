import { createContext, useState } from "react";

const ConfirmationContext = createContext();

const ConfirmationProvider = ({ children }) => {
  const [data, setData] = useState({
    isOpen: false,
    context: "",
    contextId: "",
    callbacks: null,
  });
  const { isOpen, context, contextId, callbacks } = data;

  const showConfirmation = (context, id = "", callbacks = null) => {
    setData({
      ...data,
      isOpen: !isOpen,
      context: context,
      contextId: id,
      callbacks: callbacks,
    });
  };

  return (
    <ConfirmationContext.Provider
      value={{ isOpen, contextId, context, showConfirmation, callbacks }}
    >
      {children}
    </ConfirmationContext.Provider>
  );
};

export { ConfirmationProvider, ConfirmationContext };

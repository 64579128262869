import React, { useEffect } from "react";

function Loading() {
  useEffect(() => {
    window.document.body.style.backgroundColor = "#2255F9";

    return () => {
      window.document.body.style.backgroundColor = "#FFF";
    };
  }, []);

  return (
    <div
      className="spinner-grow d-flex justify-content-center"
      style={{ width: "7rem", height: "7rem" }}
      role="status"
    >
      <span className="visually-hidden"></span>
    </div>
  );
}

export default Loading;

import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

const GlobalContext = createContext();

function GlobalProvider({ children }) {
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterLimit, setFilterLimit] = useState(10);
  const [filterSearch, setFilterSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const contextValue = {
    activeTab,
    setActiveTab,
    page,
    setPage,
    openFilter,
    setOpenFilter,
    filterStatus,
    setFilterStatus,
    filterLimit,
    setFilterLimit,
    filterSearch,
    setFilterSearch,
    isLoading,
    setIsLoading,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};

export { GlobalContext, GlobalProvider };

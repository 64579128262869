import instance from "./axios";

export const login = async (path, form) => {
  // const data = await instance.post(`/${path}`, form)
  // return data
};

export const signIn = async (form) => {
  const data = await instance.post("/signin", form);

  return data;
};

export const signout = async (codDocumentoPessoa) => {
  const { data } = await instance.post("/signout", {
    body: { userId: codDocumentoPessoa },
  });

  return data;
};

export const getUserProfiles = async (codDocumentoPessoa) => {
  const { data } = await instance.get(
    `/profiles/user_profiles/${codDocumentoPessoa}`,
  );

  return data;
};

export const getJSONbyProfile = async (nameProfileUser) => {
  const { data } = await instance.get(
    `/profiles/profiles_by_name/${nameProfileUser}`,
  );

  return data;
};

export const signupConfirmation = async (form) => {
  const data = await instance.post("/signup_confirmation", form);

  return data;
};

export const forgottenPassword = async (form) => {
  const data = await instance.post("/forgotten_password", form);

  return data;
};

export const confirmationForgottenPassword = async (form) => {
  const data = await instance.post("/confirmation_forgotten_password", form);

  return data;
};

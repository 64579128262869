import instance from "./axios";

export const getAllUser = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId,
  relationId,
  issuerId,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}&id_pessoa_emitente=${issuerId}`;
  const data = await instance.get(
    `/user_person/all_users_paginate/${queryStringParameters}`,
  );

  return data;
};

export const getUser = async (id) => {
  const { data } = await instance.get(`/user_person/get_id/${id}`);

  return data;
};

export const getSelectsTests = async () => {
  const data = await instance.get("/client_all_person_test");

  return data;
};

export const createNewUser = async (form) => {
  const data = await instance.post("/user_person", form);

  return data;
};

export const updateUser = async (id_pessoa_usuario, form) => {
  const data = await instance.put(`/user_person/${id_pessoa_usuario}`, form);

  return data;
};

export const getProfile = async () => {
  const { data } = await instance.get(`/get_types/perfil_sistema`);

  return data;
};

export const generateProfileJsonByProfileId = async (id) => {
  const { data } = await instance.get(`/generate_profile_json/${id}`);

  return data;
};

export const searchPersons = async (value, typePerson) => {
  const { data } = await instance.get(
    `/search_person/${typePerson}?search_data=${value}`,
  );

  return data;
};

export const deleteUser = async (id) => {
  const data = await instance.delete(`/user_person/${id}`, {
    data: {
      cod_usuario: "teste",
    },
  });

  return data;
};

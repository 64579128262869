import instance from "./axios";

export const insertSynonym = async (form) => {
  const data = await instance.post("/synonyms_tag", form);

  return data;
};

export const getAllSynonyms = async (
  page_number = 1,
  filterLimit = 10,
  filterSearch,
  filterStatus,
  customerId,
  partnerId,
  relationId,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}&id_pessoa_cliente=${customerId}&id_pessoa_parceiro=${partnerId}&id_pessoa_relacionamento=${relationId}`;
  const data = await instance.get(
    `/synonyms_tag/all_synonyms_tag_paginate/${queryStringParameters}`,
  );

  return data;
};

export const getSynonymById = async (id_pesoa_emitente_tag_extra) => {
  const data = await instance.get(
    `/synonyms_tag/get_id/${id_pesoa_emitente_tag_extra}`,
  );

  return data;
};

export const getIdTagExtra = async () => {
  const data = await instance.get("/get_types/tag_extra");

  return data;
};

export const getTipXml = async () => {
  const data = await instance.get("/get_types/xml");

  return data;
};

export const getIdAverbadora = async () => {
  const data = await instance.get("/get_types/averbadora");

  return data;
};

export const updateSynonym = async (id_relac_emitente_tag_extra, form) => {
  const data = await instance.put(
    `/synonyms_tag/${id_relac_emitente_tag_extra}`,
    form,
  );

  return data;
};

export const deleteSynonym = async (id_pesoa_emitente_tag_extra) => {
  await instance.delete(`/synonyms_tag/${id_pesoa_emitente_tag_extra}`, {
    data: { cod_usuario: "DELETE CHAVE" },
  });
};

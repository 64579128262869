import instance from "./axios";

export const getTypes = async (tableName) => {
  const data = await instance.get(`get_types/${tableName}`);

  return data;
};

export const getTypesByTypePerson = (typePerson) => {
  // type_person = cliente, parceiro, relacionamento e emitente
  const data = instance.get(`/get_person_types/${typePerson}`);

  return data;
};

export const getIdClientByCNPJ = async (cnpj) => {
  const data = await instance.get(
    `/client/client_by_code/${cnpj.replace(/\D/g, "")}`,
  );

  return data;
};

export const getPartnerByClientId = async (clientId) => {
  const data = await instance.get(
    `/client_partner/all_client_partner_by_client_id/${clientId}`,
  );

  return data;
};

export const getRelationByPartnerId = async (partnerId) => {
  const data = await instance.get(
    `/client_relation/all_client_relation_by_partner_id/${partnerId}`,
  );
  return data;
};

export const searchCnpja = async (value) => {
  const data = await instance.get(`search_cnpja/${value.replace(/\D/g, "")}`);

  return data;
};

export const searchCep = async (value) => {
  const data = await instance.get(`search_cep/${value.replace(/\D/g, "")}`);

  return data;
};

export const getAllClientByFilter = async (
  page_number,
  filterLimit,
  filterSearch,
  filterStatus,
) => {
  const queryStringParameters = `${page_number}?limit=${filterLimit}&search_data=${filterSearch}&tip_status=${filterStatus}`;
  const data = await instance.get(
    `/client/all_client_paginate/${queryStringParameters}`,
  );
  return data;
};

export const getClientById = async (id_pessoa_cliente) => {
  const data = instance.get(`/client/get_id/${id_pessoa_cliente}`);

  return data;
};

export const createClient = async (form) => {
  const data = await instance.post("/client", form);

  return data;
};

export const updateClient = async (form, id_pessoa_cliente) => {
  const data = await instance.put(`/client/${id_pessoa_cliente}`, form);

  return data;
};

export const updateStatusClient = async (id_pessoa_cliente, tip_status) => {
  const params = {
    tip_status: tip_status,
    cod_usuario: "Usiario Teste", // verificar isso aqui
  };

  const data = await instance.put(
    `/client/status/${id_pessoa_cliente}`,
    params,
  );

  return data;
};

export const deleteClient = async (id_pessoa_cliente) => {
  const { data } = await instance.delete(`/client/${id_pessoa_cliente}`, {
    data: { cod_usuario: "Usuario Teste" }, // verificar isso aqui
  });

  return data;
};

export const deleteAddress = async (id_endereco) => {
  await instance.delete(`/delete_address/${id_endereco}`, {
    data: { cod_usuario: "Usuario Teste" }, // verificar isso aqui
  });
};

export const deleteContact = async (id_contato) => {
  await instance.delete(`/delete_contact/${id_contato}`, {
    data: { cod_usuario: "Usuario Teste" },
  });
};

export const checkCnpjExists = async (cnpj) => {
  const data = await instance.get(
    `/person/check_cnpj_exists/${cnpj.replace(/\D/g, "")}`,
  );

  return data;
};

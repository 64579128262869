export const customStyles = {
  overlay: { background: "rgba(0, 0, 0, 0.9)", zIndex: 999 },
  zIndex: "99999999999999999999999999",
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    overflow: "hidden",
    height: "auto",
    width: "auto",
    index: "99999999999",
  },
};

import React from "react";
import { lazy } from "react";
import { Switch } from "react-router-dom";

import MainLayout from "../layouts/Main";

import PrivateRoute from "./components/PrivateRoute";

const MainPage = lazy(() => import("../pages/MainPages"));
const Endorsement = lazy(() => import("../pages/MainPages/Endorsement"));
// const ViewManifest = lazy(() =>
//   import("../pages/MainPages/ConsultManifest/pages/ViewManifest")
// );

const ProtectedRoute = () => (
  <Switch>
    <MainLayout>
      <PrivateRoute exact path="/main/:id" children={<MainPage />} />
      <PrivateRoute
        exact
        path="/main/manifesto/:id?"
        // children={<ViewManifest />}
      />
      <PrivateRoute
        feature="Averbação"
        exact
        path="/endorsement/:id?"
        children={<Endorsement />}
      />
      <PrivateRoute
        feature="Averbação"
        exact
        path="/endorsement/recusados/:id?"
        children={<Endorsement />}
      />
    </MainLayout>
  </Switch>
);

export default ProtectedRoute;

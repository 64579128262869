import { memo } from "react";
import "./styles.scss";

const FieldTextError = ({ children }) => {
  return (
    <div className="text-muted">
      <div className="wrapper-field-text-error">{children}</div>
    </div>
  );
};

export default memo(FieldTextError);

import { Progress } from "reactstrap";

function IsLoading() {
  return (
    <div style={{ padding: "2em" }}>
      <Progress animated color="success" value="100" />
    </div>
  );
}

export default IsLoading;

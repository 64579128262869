import { useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import useAuthStore from "../../../../../../context/Auth/useAuthStore";
import {
  getJSONbyProfile,
  getUserProfiles,
} from "../../../../../../services/authentication";

function FeatureSelectFactory() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user, setProfile } = useAuthStore();

  const { data, isLoading, isError } = useQuery(
    ["getUserProfiles", { codDocumentoPessoa: user.codDocumentoPessoa }],
    () => getUserProfiles(user.codDocumentoPessoa),
    {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const onSubmit = async (values, internalCall = false, toogleModal = null) => {
    try {
      setLoading(true);
      const { data } = await getJSONbyProfile(values.name_profile_user);

      setProfile(
        data.json_perfil,
        data.des_perfil_sistema,
        data.id_usuario_perfil,
      );

      history.push("/main/dashboard");
      document.location.reload(true);
      toast.success(
        internalCall ? "Alterado com sucesso" : "Logado com sucesso",
      );

      if (toogleModal && internalCall) toogleModal();
    } catch (e) {
      toast.error("Não foi possivel encontrar um perfil.");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    profiles: data,
    isLoading,
    isError,
    onSubmit,
  };
}

export default FeatureSelectFactory;

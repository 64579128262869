import create from "zustand";
import { persist } from "zustand/middleware";
import { signIn, signout } from "../../services/authentication";
import toast from "react-hot-toast";

const useAuthStore = create(
  persist(
    (set, get) => ({
      modalIsOpen: false,
      loading: false,
      isLogged: false,
      id_perfil_sistema: null,
      json_perfil: null,
      id_usuario_perfil: "",
      user: {
        codDocumentoPessoa: "",
        idToken: "",
        email: "",
        username: "",
        acceptedTerms: false,
        idPessoaUsuario: "",
        full_name: "",
      },

      updateUser: () => {
        const { user } = get();
        set({
          user: { ...user, acceptedTerms: true },
        });
      },

      setProfile: (jsonPerfil, idPerfilSistema, idUsuario) => {
        set({
          id_perfil_sistema: idPerfilSistema,
          json_perfil: jsonPerfil,
          isLogged: true,
          modalIsOpen: false,
          id_usuario_perfil: idUsuario,
        });
      },

      logout: async () => {
        const toastId = toast.loading("Processando");
        const { user, reset } = get();

        try {
          await signout(user.codDocumentoPessoa);
        } catch (e) {
        } finally {
          toast.success("Logout efetuado com sucesso");
          toast.remove(toastId);
          reset();
        }
      },

      login: async (formData, setFieldError) => {
        try {
          set({ loading: true });
          const { data } = await signIn(formData);

          set({
            modalIsOpen: true,
            user: {
              idToken: data.AuthenticationResult.IdToken,
              email: "",
              username: "",
              codDocumentoPessoa: data.cod_documento_pessoa,
              acceptedTerms: data.acceptedTerms,
              idPessoaUsuario: data.id_pessoa_usuario,
              full_name: data.full_name ?? "",
              cod_usuario: data.cod_usuario,
            },
          });
        } catch (e) {
          setFieldError("errorMessage", e.response.data.error);
        } finally {
          set({ loading: false });
        }
      },

      setLoading: () => {
        const { loading } = get();
        set({ loading: !loading });
      },

      toogleModal: () => {
        const { modalIsOpen } = get();
        set({ modalIsOpen: !modalIsOpen });
      },

      reset: () => {
        set({
          modalIsOpen: false,
          loading: false,
          isLogged: false,
          id_perfil_sistema: null,
          json_perfil: null,
          id_usuario_perfil: "",
          user: {
            codDocumentoPessoa: "",
            idToken: "",
            email: "",
            username: "",
            acceptedTerms: false,
            idPessoaUsuario: "",
            full_name: "",
            cod_usuario: "",
          },
        });
      },
    }),
    {
      name: process.env.REACT_APP_APPLICATION_KEY,
      getStorage: () => localStorage,
    },
  ),
);

export default useAuthStore;

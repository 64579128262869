import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  userId: Yup.string()
    .min(4, "Muito curto")
    .max(40, "Muito longo")
    // .matches(/^[A-Za-z]*$/, 'Por favor insira um nome válido')
    .required("Campo obrigatório"),

  password: Yup.string().required("Campo obrigatório"),
  checkbox: Yup.boolean().nullable(),
});

export const loginProfileValidationSchema = Yup.object().shape({
  name_profile_user: Yup.string().required("Campo obrigatório"),
});

export const confirmationValidationSchema = Yup.object().shape({
  userId: Yup.string().required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
  password_tmp: Yup.string().required("Campo obrigatório"),
  errorMessage: Yup.string().nullable(),
});

export const passwordConfirmationValidationSchema = Yup.object().shape({
  userId: Yup.string().required("Campo obrigatório"),
  errorMessage: Yup.string().nullable(),
});
